import React from 'react';
import TeamworkComponent from '../components/landingComponents/teamworkComponent.js';
import FeatureComponent from '../components/landingComponents/featureComponent.js';
import SuccessComponent from '../components/landingComponents/successComponet.js';
import FormLandingPage from '../components/landingComponents/formComponentLandingPage.js';
import { graphql } from 'gatsby';
import Layout from '../components/layout.js';
import NewFooterComponent from '../components/newFooterComponent'
import Header from '../components/headerComponent.js';
import ChangeMakerTestiomonialComponent from '../components/landingComponents/changeMakerTestimonialComponent.js';
import StepsComponent from '../components/landingComponents/stepsComponent.js';
import getArtime from '../images/getArtime.png';
import getTraction from '../images/getTraction.png';
import WinByIn from '../images/winByIn.png';
import BuildTrustImage from '../images/buildTrust.png';
import EasyApprovalImage from '../images/easyApproval.png';
const listItem = [
  {
    title: 'Get Airtime',
    subtitle: 'Get airtime with higher-ups by speaking their language.',
    description:
      'With Agreed your ideas become value propositions that leaders love to hear.​ ',
    image: getArtime,
  },
  {
    title: 'Get Traction',
    subtitle: 'Leaders prioritize ideas with measurable value.',
    description:
      'Agreed helps you quickly build a strong business case by defining financial factors and gauging ROI.​',
    image: getTraction,
  },
  {
    title: 'Build Trust',
    subtitle: 'Ideas build trust with facts, not guesses.',
    description:
      'Agreed helps you verify and document data points so that everyone believes the numbers and shares a single source of truth.​​​​',
    image: BuildTrustImage,
  },
  {
    title: 'Win Buy-In',
    subtitle: 'Stakeholders can make or break your idea.',
    description:
      'Agreed helps you win buy-in by engaging stakeholders in the right way at the right time.',
    image: WinByIn,
  },
  {
    title: 'Easy Approval',
    subtitle: 'No more gridlock at decision time.',
    description:
      'Agreed streamlines group approval and gives business leaders everything they need to say YES.',
    image: EasyApprovalImage,
  },
];
const sliderItem = [
  "Slide1",
  "Slide2",
  "Slide3"
]
const LandingPage = props => {
  const {
    data: {
      wpPage: {
        seo,
        landingPageLeadSection: { leadButton, leadSubtitle, leadTitle },
        landingPageIdeasSection: {
          championDescriptionCase,
          championDescriptionChanges,
          championDescriptionThings,
          championIdeaTitle,
          championButton,
          championImage: {
            localFile: {
              childrenImageSharp: [{ gatsbyImageData: championImage }],
            },
          },
        },
        offerBrakePointSection: {
          landingBrakePoint,
          landingBrakePointSubtitle,
          bankOfAmericaImage: {
            localFile: {
              childrenImageSharp: [{ gatsbyImageData: bankAmericaImageOf }],
            },
          },
          guthyImage: {
            localFile: {
              childrenImageSharp: [{ gatsbyImageData: guthyImage }],
            },
          },
          humanaImage: {
            localFile: {
              childrenImageSharp: [{ gatsbyImageData: humanaImage }],
            },
          },
          mellonImage: {
            localFile: {
              childrenImageSharp: [{ gatsbyImageData: mellonImage }],
            },
          },
          mrvImage: {
            localFile: {
              childrenImageSharp: [{ gatsbyImageData: mrvImage }],
            },
          },
          mufgImage: {
            localFile: {
              childrenImageSharp: [{ gatsbyImageData: mufgImage }],
            },
          },
          nyseImage: {
            localFile: {
              childrenImageSharp: [{ gatsbyImageData: nyseImage }],
            },
          },
        },
        landingPageGetFreeAccountSection: {
          landingFreeAccountButton,
          landingFreeAccountTitle,
          landingFreeAccountImage: {
            localFile: {
              childrenImageSharp: [
                { gatsbyImageData: landingFreeAccountImage },
              ],
            },
          },
        },
        stepsSectionChangeMaker: {
          buildtrustImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: buildtrustImage },
            },
          },

          getairtimeIamge: {
            localFile: {
              childImageSharp: { gatsbyImageData: getairtimeIamge },
            },
          },
          gettractionImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: gettractionImage },
            },
          },
          streamlineapprovalImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: streamlineapprovalImage },
            },
          },
          winbuyImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: winbuyImage },
            },
          },
        },
      },
    },
    pageContext: { title },
  } = props;
  return (
    <Layout seo={seo} title={title}>
      <Header />
      <TeamworkComponent
        teamworkTitle={leadTitle}
        teamworkDescription={leadSubtitle}
        teamworkButtonText={leadButton}
      />
      <FeatureComponent
        featureTitle={championIdeaTitle}
        featureWorkDescription={championDescriptionThings}
        featureChangeDescription={championDescriptionChanges}
        featureBusinessDescription={championDescriptionCase}
        featureButtonText={championButton}
        FeatureImage={championImage}
        isChangeMaker={true}
      />
      <SuccessComponent
        bankOfAmericaImage={bankAmericaImageOf}
        guthyImage={guthyImage}
        humanImage={humanaImage}
        mellonImage={mellonImage}
        MRVImage={mrvImage}
        mufgImage={mufgImage}
        nyseImage={nyseImage}
        sucessTitle={landingBrakePoint}
        sucessSubtitle={landingBrakePointSubtitle}
      />
      <StepsComponent
        listItem={listItem}
        GetAirtime={getairtimeIamge}
        GetTraction={gettractionImage}
        BuildTrust={buildtrustImage}
        WinBuy={winbuyImage}
        StreamlineApproval={streamlineapprovalImage}
      />
      <ChangeMakerTestiomonialComponent
        sliderItem={sliderItem}
        testimonialTitle="Agreed is the proven platform for change makers."
      />
      <FormLandingPage
        freeAccountTitle={landingFreeAccountTitle}
        freeAccountButton={landingFreeAccountButton}
        spotLightImage={landingFreeAccountImage}
      />
      <NewFooterComponent isProduct={true} />
    </Layout>
  );
};
export const query = graphql`
  query {
    wpPage(slug: { eq: "lead-organisational-change" }) {
      seo {
        metaDesc
        title
      }
      landingPageGetFreeAccountSection {
        landingFreeAccountButton
        landingFreeAccountTitle
        landingFreeAccountImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      landingPageReviewsSection {
        landingReviewGreg
        landingReviewsErin
        landingReviewsImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        landingReviewsSteffen
        landingReviewsTitle
        reviewsNameSliderOne
        reviewsNameSliderThree
        reviewsNameSliderTwo
        reviewsPositionSliderOne
        reviewsPositionSliderThree
        reviewsPositionSliderTwo
      }
      landingPageHeader {
        landingButtonTitle
      }
      landingPageLeadSection {
        leadButton
        leadSubtitle
        leadTitle
      }
      landingPageIdeasSection {
        championDescriptionCase
        championDescriptionChanges
        championDescriptionThings
        championIdeaTitle
        championButton
        championImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      offerBrakePointSection {
        landingBrakePoint
        landingBrakePointSubtitle
        pairwiesLogo {
          localFile {
            childrenImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        bankOfAmericaImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(
                backgroundColor: "transparent"
                placeholder: BLURRED
              )
            }
          }
        }
        guthyImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(
                backgroundColor: "transparent"
                placeholder: BLURRED
              )
            }
          }
        }
        humanaImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(
                backgroundColor: "transparent"
                placeholder: BLURRED
              )
            }
          }
        }
        mellonImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(
                backgroundColor: "transparent"
                placeholder: BLURRED
              )
            }
          }
        }
        mrvImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(
                backgroundColor: "transparent"
                placeholder: BLURRED
              )
            }
          }
        }
        mufgImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(
                backgroundColor: "transparent"
                placeholder: BLURRED
              )
            }
          }
        }
        nyseImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(
                backgroundColor: "transparent"
                placeholder: BLURRED
              )
            }
          }
        }
        panasonicImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(
                backgroundColor: "transparent"
                placeholder: BLURRED
              )
            }
          }
        }
        pncImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(
                backgroundColor: "transparent"
                placeholder: BLURRED
              )
            }
          }
        }
      }
      landingPageExplanationSection {
        landingButtonText
        landingGoodIdeaDescription
        landingGoodIdeaImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        landingGoodIdeaTitle
        landingInProgressIdeaDescription
        landingInProgressIdeaImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        landingInProgressIdeaTitle
        landingRealiseIdeaDescription
        landingRealiseIdeaImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        landingRealiseIdeaTitle
        landingRightIdeaDescription
        landingRightIdeaImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        landingRightIdeaTitle
        landingThinkIdeDescription
        landingThinkIdeaImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        landingThinkIdeaTitle
      }
      stepsSectionChangeMaker {
        buildtrustImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        deliverroiwithcertaintyImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        getairtimeIamge {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        gettractionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        streamlineapprovalImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        winbuyImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
export default LandingPage;
