import React, { useRef, useEffect } from 'react';
import Lottie from 'lottie-web';
import { Link } from 'gatsby';
const TeamworkComponent = props => {
  const container = useRef(null);
  const { teamworkTitle, teamworkDescription, teamworkButtonText } = props;
  useEffect(() => {
    Lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('../../animation/teamwork.json'),
    });
  }, []);
 
  return (
    <div className="teamwork-section">
      <div className="full-container">
        <div className="team-section">
          <div className="row">
            <div className="col-lg-6 col-md-7 col-sm-12 teamwork-section_text order-md-1 order-2" >
              <h1 className="teamwork-section_title">{teamworkTitle}</h1>
              <p className="teamwork-section_description">
                {teamworkDescription}
              </p>
              <div className="teamwork-section_form-wrapper">
                <Link
                  to="/contact"
                  className="help-section-link"
                  // className="teamwork-section_form-button"
                  // onClick={handleLangSwitch}
                >
                  {teamworkButtonText}
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-md-5 col-sm-12 order-md-2 order-1">
              <div className="lotti-container" ref={container}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TeamworkComponent;
