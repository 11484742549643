import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const FeatureComponent = props => {
  const {
    featureTitle,
    featureBusinessDescription,
    featureButtonText,
    featureWorkDescription,
    featureChangeDescription,
    FeatureImage,
    isChangeMaker,
  } = props;
  return (
    <div className="feature-section">
      <div className="full-container">
        <div className="row">
          <div className="col-lg-7 col-md-7 col-sm-12 ">
            <div className="feature-section_image-col">
              <GatsbyImage
                imgStyle={{ width: '100%' }}
                image={FeatureImage}
                alt={''}
                className={`feature-section_image ${
                  isChangeMaker ? 'feature-section_image-change' : ''
                }`}
              />
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12  feature-section-text-col">
            <p
              className={`feature-section_title ${
                isChangeMaker ? 'feature-section_title-changemaker' : ''
              }`}
            >
              {featureTitle}
              <span className="feature-section_title-border"></span>
            </p>
            <p
              className={`feature-section_text ${
                isChangeMaker ? 'feature-section_text-change' : ''
              }`}
            >
              {featureWorkDescription}
            </p>
            <p
              className={`feature-section_text ${
                isChangeMaker ? 'feature-section_text-change' : ''
              }`}
            >
              {featureChangeDescription}​
            </p>
            <p className="feature-section_description">
              {featureBusinessDescription}
            </p>
            <div className="teamwork-section_form-wrapper">
              <Link
                to="/contact"
                className="help-section-link"
                // className="teamwork-section_form-button"
              >
                {featureButtonText}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FeatureComponent;
