import React, { useRef, useEffect } from 'react';
import Lottie from 'lottie-web';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const FormLandingPage = props => {
  const container = useRef(null);
  const { freeAccountTitle, freeAccountButton, spotLightImage } = props;
  useEffect(() => {
    Lottie.loadAnimation({
      container: container.current,
      loop: true,
      autoplay: true,
      animationData: require('../../animation/lottieForm.json'),
    });
  }, []);
  const handleLangSwitch = () => {
    window.location.href = 'https://app.agreed.io/signup';
  };
  return (
    <div className="full-container">
      <div className="form-landingPage-section">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 form-landingPage-section-text-col">
            <p className="form-landingPage-section_subtitle">
              {freeAccountTitle}
            </p>

            <div className="teamwork-section_form-wrapper landingpage-button">
              <Link
                to="/contact"
                className="help-section-link"
                // className="teamwork-section_form-button"
                onKeyDown={handleLangSwitch}
              >
                {freeAccountButton}
              </Link>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-img">
            <GatsbyImage image={spotLightImage} className="lotti" alt={''} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default FormLandingPage;
